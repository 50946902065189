"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeText = void 0;
var slate_1 = require("slate");
var EditorTypes_1 = require("../EditorTypes");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var Styleable_1 = require("../WidgetRegistry/Utils/Styleable");
var EditorWidgetRegistry_1 = require("../WidgetRegistry/EditorWidgetRegistry");
var normalizeText = function (entry, editor, schema, invisibleOverridesNormalizer) {
    var e_1, _a;
    var _b = __read(entry, 2), node = _b[0], path = _b[1];
    var ancestors = slate_1.Path.ancestors(path, { reverse: true });
    if (!node.marks) {
        slate_1.Transforms.setNodes(editor, {
            marks: [],
        }, {
            at: path,
        });
        return true;
    }
    var textElementConfig = undefined;
    var validMarks;
    try {
        for (var ancestors_1 = __values(ancestors), ancestors_1_1 = ancestors_1.next(); !ancestors_1_1.done; ancestors_1_1 = ancestors_1.next()) {
            var ancestor = ancestors_1_1.value;
            var currAncestor = slate_1.Editor.node(editor, ancestor);
            var currType = currAncestor[0].type;
            var registryConfig = (0, EditorWidgetRegistry_1.getGenericConfigByType)(currType);
            if (registryConfig) {
                if (registryConfig.nodeType === "textElement") {
                    textElementConfig = registryConfig;
                    validMarks = __spreadArray([
                        EditorTypes_1.MarkType.Bold,
                        EditorTypes_1.MarkType.Italic,
                        EditorTypes_1.MarkType.Strikethrough,
                        EditorTypes_1.MarkType.Comment
                    ], __read((0, CommonUtils_1.includeIf)((0, Styleable_1.textWidgetStylesEnabled)(editor, path), [EditorTypes_1.MarkType.Color])), false);
                    break;
                }
            }
            else {
                var parentConfig = schema.inlines[currType] || schema.blocks[currType];
                if (!parentConfig) {
                    continue;
                }
                if (parentConfig.marks != undefined) {
                    if (!parentConfig.marks) {
                        validMarks = [];
                    }
                    else {
                        validMarks = __spreadArray([], __read(parentConfig.marks), false);
                    }
                    break;
                }
                else if (parentConfig.isVoid) {
                    break;
                }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (ancestors_1_1 && !ancestors_1_1.done && (_a = ancestors_1.return)) _a.call(ancestors_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (!validMarks) {
        return false;
    }
    var filteredMarks = node.marks.filter(function (mark) {
        if (!validMarks.includes(mark.type))
            return false;
        if (invisibleOverridesNormalizer && textElementConfig) {
            return invisibleOverridesNormalizer.isOverrideMarkVisible(textElementConfig.type, mark);
        }
        return true;
    });
    if (filteredMarks.length !== node.marks.length) {
        slate_1.Transforms.setNodes(editor, { marks: filteredMarks }, { at: path, voids: true });
        return true;
    }
    return false;
};
exports.normalizeText = normalizeText;
