"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterToolbarFeatures = void 0;
function filterToolbarFeatures(toolbarFeatures, containerElementType, isSavedBlock) {
    return toolbarFeatures.filter(function (toolbarFeature) {
        switch (toolbarFeature) {
            case "comment":
                return !isSavedBlock;
            case "link":
                return containerElementType !== "accordionHeading";
        }
        return true;
    });
}
exports.filterToolbarFeatures = filterToolbarFeatures;
