"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.areColorsEqual = exports.isOverrideInvisible = exports.styleablePropertiesAreEqual = exports.textWidgetStylesEnabled = exports.removeStyleOverrides = void 0;
var colord_1 = require("colord");
var lodash_1 = require("lodash");
var slate_1 = require("slate");
var EditorTypes_1 = require("Project/QwilrEditor/EditorTypes");
var EditorWidgetRegistry_1 = require("../EditorWidgetRegistry");
var styleablePropertiesAreEqual = function (a, b, propertyDefinition) {
    var comparable = [a, b, propertyDefinition];
    if (isColorProperty(comparable)) {
        return areColorsEqual(comparable[0], comparable[1]);
    }
    else if (isFontSizeProperty(comparable)) {
        return areFontSizesEqual(comparable[0], comparable[1]);
    }
    return (0, lodash_1.isEqual)(a, b);
};
exports.styleablePropertiesAreEqual = styleablePropertiesAreEqual;
var isOverrideInvisible = function (baseValue, overrideValue, propertyDefinition) {
    var comparable = [baseValue, overrideValue, propertyDefinition];
    if (isColorProperty(comparable)) {
        return (0, colord_1.colord)(comparable[0].abs).isEqual((0, colord_1.colord)(comparable[1].abs));
    }
    else if (isFontSizeProperty(comparable)) {
        return comparable[0].abs === comparable[1].abs;
    }
    return (0, lodash_1.isEqual)(baseValue, overrideValue);
};
exports.isOverrideInvisible = isOverrideInvisible;
var isColorProperty = function (comparable) {
    return comparable[2].type === "color";
};
var areColorsEqual = function (a, b) {
    if (a.themeIndex !== b.themeIndex) {
        return false;
    }
    if (a.opacity !== b.opacity) {
        return false;
    }
    if (a.themeIndex == null) {
        if (a.abs !== b.abs) {
            return false;
        }
    }
    return true;
};
exports.areColorsEqual = areColorsEqual;
var isFontSizeProperty = function (comparable) {
    return comparable[2].type === "fontSize";
};
var areFontSizesEqual = function (a, b) {
    if (a.themeRef !== b.themeRef) {
        return false;
    }
    if (a.themeRef == null) {
        if (a.abs !== b.abs) {
            return false;
        }
    }
    return true;
};
var removeStyleOverrides = function (root) {
    var e_1, _a;
    try {
        for (var _b = __values(slate_1.Node.nodes(root)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 1), node = _d[0];
            if (slate_1.Element.isElement(node)) {
                if ("style" in node) {
                    delete node["style"];
                }
            }
            if (slate_1.Text.isText(node)) {
                node.marks = node.marks.filter(function (m) { return m.type !== EditorTypes_1.MarkType.Color; });
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
exports.removeStyleOverrides = removeStyleOverrides;
var textWidgetStylesEnabled = function (editor, path) {
    var e_2, _a;
    var _b;
    if (!editor.schema.textStylesEnabled) {
        return false;
    }
    try {
        for (var _c = __values(slate_1.Node.ancestors(editor, path)), _d = _c.next(); !_d.done; _d = _c.next()) {
            var ancestorEntry = _d.value;
            var _e = __read(ancestorEntry, 1), ancestor = _e[0];
            if (slate_1.Element.isElement(ancestor)) {
                var ewrConfig = (0, EditorWidgetRegistry_1.getGenericConfigByType)(ancestor.type);
                if (!ewrConfig) {
                    continue;
                }
                if (ewrConfig.nodeType === "layoutElement" &&
                    !!((_b = ewrConfig.containerNodeConfiguration) === null || _b === void 0 ? void 0 : _b.preventTextContextualStyles)) {
                    return false;
                }
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return true;
};
exports.textWidgetStylesEnabled = textWidgetStylesEnabled;
