// Generated by CoffeeScript 1.12.7
/**
 * @deprecated. This file is no longer in use. The only reason it has not been deleted
 * is because another CoffeeScript migration file imports it as a dependency and I
 * don't want to break the migration diff there for when it eventually gets converted.
 *
 * If you are the lucky person migrating Client/Application/SettingsAngular/Account/Service/Service.js
 * I am happy to inform you that you can delete this file and remove that import!
 */

(function () {
  module.exports = function (account, user, internalSlack, $http, apiBaseUrl, getFullName) {
    return function (phoneNumber) {
      return $http
        .post(apiBaseUrl + "/accounts/" + account._id + "/change-phone-number", {
          phoneNumber: phoneNumber,
        })
        .then(function () {
          internalSlack.ping(
            "Team, " +
              getFullName(user) +
              ", of " +
              account.name +
              "\nJust updated their phone number to: " +
              phoneNumber,
            "#phone",
          );
          return phoneNumber;
        });
    };
  };
}.call(this));
