"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvisibleOverridesNormalizer = void 0;
var lodash_1 = require("lodash");
var widgetStyle_1 = require("@CommonFrontend/Utils/widgetStyle");
var ColorProvider_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorProvider");
var GetTraitConfig_1 = require("@CommonFrontend/WidgetRegistry/Utils/GetTraitConfig");
var Styleable_1 = require("./WidgetRegistry/Utils/Styleable");
var StyleableActions_1 = require("./Behaviours/Styleable/StyleableActions");
var Text_1 = require("@CommonFrontend/ContentTokens/Models/Text");
var InvisibleOverridesNormalizer = (function () {
    function InvisibleOverridesNormalizer(blockStyleStore, brandStore) {
        this.blockStyleStore = blockStyleStore;
        this.brandStore = brandStore;
    }
    InvisibleOverridesNormalizer.prototype.getNormalizedOverrides = function (node) {
        var e_1, _a;
        var elementType = node.type, overrides = node.style;
        var baseInfo = this.getElementBaseInfo(elementType);
        if (!baseInfo)
            return null;
        var styleableConfig = baseInfo.styleableConfig, baseStyle = baseInfo.baseStyle;
        var combinedStyle = this.getComputedStyle(overrides, styleableConfig);
        var operations = getInvisibleOverrideOperations(overrides, styleableConfig, baseStyle, combinedStyle, node);
        if (!operations)
            return null;
        var normalizedOverrides = (0, lodash_1.cloneDeep)(overrides);
        try {
            for (var operations_1 = __values(operations), operations_1_1 = operations_1.next(); !operations_1_1.done; operations_1_1 = operations_1.next()) {
                var op = operations_1_1.value;
                switch (op.type) {
                    case "remove": {
                        (0, StyleableActions_1.recursivelyUnset)(normalizedOverrides, op.path);
                        break;
                    }
                    case "set_default": {
                        (0, lodash_1.set)(normalizedOverrides, op.path, op.default);
                        break;
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (operations_1_1 && !operations_1_1.done && (_a = operations_1.return)) _a.call(operations_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return normalizedOverrides;
    };
    InvisibleOverridesNormalizer.prototype.isOverrideMarkVisible = function (elementType, mark) {
        if (mark.type === Text_1.MarkType.Color) {
            var baseInfo = this.getElementBaseInfo(elementType);
            if (!baseInfo)
                return false;
            var styleableConfig = baseInfo.styleableConfig, baseStyle = baseInfo.baseStyle;
            var colorPath_1 = "color";
            var property = styleableConfig.properties.find(function (p) { return p.type === "color" && p.path === colorPath_1; });
            if (!property)
                return false;
            if ((0, Styleable_1.isOverrideInvisible)((0, lodash_1.get)(baseStyle, colorPath_1), mark.data.color, property)) {
                return false;
            }
        }
        return true;
    };
    InvisibleOverridesNormalizer.prototype.getElementBaseInfo = function (elementType) {
        var styleableConfig = (0, GetTraitConfig_1.getTraitConfig)({ widgetType: elementType, traitName: "styleable" });
        if (!styleableConfig)
            return null;
        var baseStyle = this.getComputedStyle({}, styleableConfig);
        return { styleableConfig: styleableConfig, baseStyle: baseStyle };
    };
    InvisibleOverridesNormalizer.prototype.getComputedStyle = function (overrides, styleableConfig) {
        var blockStyle = this.blockStyleStore.model[styleableConfig.styleKey] || null;
        return (0, widgetStyle_1.widgetStyle)({
            blockStyle: blockStyle,
            colorProvider: new ColorProvider_1.ColorProvider({ allColors: this.brandStore.allColorsWithThemeIndex }),
            overrides: overrides,
            styleableConfig: styleableConfig,
            paragraphBlockStyle: this.blockStyleStore.getElementStyle("p"),
        });
    };
    return InvisibleOverridesNormalizer;
}());
exports.InvisibleOverridesNormalizer = InvisibleOverridesNormalizer;
function getInvisibleOverrideOperations(overrides, styleableConfig, baseStyle, combinedStyle, node) {
    var e_2, _a;
    var operations = [];
    var _loop_1 = function (property) {
        var path = property.path;
        var overrideValue = (0, lodash_1.get)(overrides, path);
        if (overrideValue !== undefined) {
            var overrideInvisible = (0, Styleable_1.isOverrideInvisible)((0, lodash_1.get)(baseStyle, path), overrideValue, property);
            var overrideNotValid = property.if && !property.if(combinedStyle, node);
            if (overrideInvisible || overrideNotValid) {
                operations.push({ type: "remove", path: path });
            }
        }
        if (property.type === "enumerable") {
            var computedValue_1 = (0, lodash_1.get)(combinedStyle, property.path);
            var matchingEnumConfig = property.options.find(function (o) { return o.value === computedValue_1; });
            if (matchingEnumConfig) {
                if (matchingEnumConfig.if && !matchingEnumConfig.if(combinedStyle)) {
                    operations.push({
                        type: "set_default",
                        default: property.defaultValue,
                        path: property.path,
                    });
                }
            }
        }
    };
    try {
        for (var _b = __values(styleableConfig.properties), _c = _b.next(); !_c.done; _c = _b.next()) {
            var property = _c.value;
            _loop_1(property);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
    if (operations.length === 0) {
        return null;
    }
    return operations;
}
