"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getComputedStyleProperty = void 0;
var lodash_1 = require("lodash");
var Styleable_1 = require("Project/QwilrEditor/WidgetRegistry/Utils/Styleable");
function getComputedStyleProperty(type, selectedTextWidgets) {
    var e_1, _a;
    var result = undefined;
    var hasSameValue = true;
    try {
        for (var selectedTextWidgets_1 = __values(selectedTextWidgets), selectedTextWidgets_1_1 = selectedTextWidgets_1.next(); !selectedTextWidgets_1_1.done; selectedTextWidgets_1_1 = selectedTextWidgets_1.next()) {
            var s = selectedTextWidgets_1_1.value;
            var property = s.styleableConfig.properties.find(function (p) { return p.type === type; });
            if (!property) {
                return { isAllowed: false };
            }
            var value = (0, lodash_1.get)(s.style, property.path);
            if (result === undefined) {
                result = {
                    property: property,
                    value: value,
                };
            }
            else if (!(0, Styleable_1.styleablePropertiesAreEqual)(result.value, value, property) && hasSameValue) {
                hasSameValue = false;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (selectedTextWidgets_1_1 && !selectedTextWidgets_1_1.done && (_a = selectedTextWidgets_1.return)) _a.call(selectedTextWidgets_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (result) {
        return { isAllowed: true, property: result.property, value: hasSameValue ? result.value : undefined };
    }
    else {
        return { isAllowed: false };
    }
}
exports.getComputedStyleProperty = getComputedStyleProperty;
