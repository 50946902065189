"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorBlockIdStore = void 0;
var EditorBlockIdStore = (function () {
    function EditorBlockIdStore() {
        this.editorToBlockId = new Map();
    }
    EditorBlockIdStore.prototype.registerEditor = function (editor, blockId) {
        this.editorToBlockId.set(editor, blockId);
    };
    EditorBlockIdStore.prototype.unregisterEditor = function (editor) {
        this.editorToBlockId.delete(editor);
    };
    EditorBlockIdStore.prototype.getBlockId = function (editor) {
        return this.editorToBlockId.get(editor);
    };
    EditorBlockIdStore.prototype.getAllEditors = function () {
        return Array.from(this.editorToBlockId.keys());
    };
    return EditorBlockIdStore;
}());
exports.EditorBlockIdStore = EditorBlockIdStore;
