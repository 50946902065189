"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var slate_react_1 = require("slate-react");
var slate_1 = require("slate");
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var CommentActions = __importStar(require("./CommentActions"));
var StylingActions = __importStar(require("./StylingActions"));
var TransformActions = __importStar(require("./TransformActions"));
var Utils_1 = require("../Utils");
var CommentCreator_1 = require("../Comments/CommentCreator");
var WidgetManager_1 = require("../WidgetManager");
exports.default = (function (di, router) {
    return {
        onReselection: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarTextReselection")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", eventType: "reselection" })
            .doAction(function (event, editor) {
            if (event.data.takeFocus) {
                slate_react_1.ReactEditor.focus(editor);
            }
            slate_1.Transforms.select(editor, event.data.selection);
            return true;
        }),
        onButtonBold: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarBold")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "bold" })
            .doAction(function (event, editor) {
            StylingActions.makeBold(editor);
            return true;
        }),
        onButtonItalic: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarItalic")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "italic" })
            .doAction(function (event, editor) {
            StylingActions.makeItalic(editor);
            return true;
        }),
        onButtonStrikeThrough: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarStrikethrough")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "strikethrough" })
            .doAction(function (event, editor) {
            StylingActions.makeStrikethrough(editor);
            return true;
        }),
        onInlineColorChange: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarColorChange")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", action: "textColorChange" })
            .doAction(function (event, editor) {
            var newColor = event.data.color;
            StylingActions.makeColorChange(editor, newColor);
            return true;
        }),
        onInlineColorRemove: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarColorRemove")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", action: "textColorRemove" })
            .doAction(function (event, editor) {
            var scope = event.data.scope;
            var widgetType = event.data.widgetType;
            StylingActions.removeColor(editor, {
                scope: scope,
                match: function (_a) {
                    var _b = __read(_a, 2), _ = _b[0], path = _b[1];
                    return widgetType
                        ? !!(0, Utils_1.checkAncestors)(editor, path, function (node) { return slate_1.Element.isElement(node) && node.type === widgetType; })
                        : true;
                },
            });
            return true;
        }),
        onButtonCommentAdd: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarCommentAdd")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "comment" })
            .doAction(function (event, editor) {
            if (!editor.selection) {
                return;
            }
            var startPoint = slate_1.Range.start(editor.selection);
            var currBlockEntry = (0, Utils_1.getClosestBlock)(editor, startPoint.path);
            if (!currBlockEntry) {
                return;
            }
            var blockElementType = currBlockEntry[0].type;
            var commentThreadStore = di.get("commentThreadStore");
            var tempId = (0, CommentCreator_1.createComment)(commentThreadStore, blockElementType, function (finalizedCommentId) {
                var eventData = {
                    action: "update",
                    fromId: tempId,
                    toId: finalizedCommentId,
                };
                router.onEvent(new WidgetManager_1.WidgetEvent("comment", eventData), editor);
            });
            var action = CommentActions.addComment(tempId);
            action(editor);
            slate_react_1.ReactEditor.blur(editor);
            return true;
        }),
        onButtonBulletListToggle: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarBulletListToggle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "listItem", listType: "bullet" })
            .doAction(function (event, editor) {
            var action = TransformActions.toggleList("bullet");
            action(editor);
            return true;
        }),
        onButtonNumberedListToggle: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarNumberedListToggle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "listItem", listType: "number" })
            .doAction(function (event, editor) {
            var action = TransformActions.toggleList("number");
            action(editor);
            return true;
        }),
        onButtonBlockQuoteToggle: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarBlockQuoteToggle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "blockQuote" })
            .doAction(function (event, editor) {
            var action = TransformActions.toggleBlock("blockQuote");
            action(editor);
            return true;
        }),
        onButtonHeading1Toggle: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarHeading1Toggle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "heading1" })
            .doAction(function (event, editor) {
            var action = TransformActions.toggleBlock("heading1");
            action(editor);
            return true;
        }),
        onButtonHeading2Toggle: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarHeading2Toggle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "heading2" })
            .doAction(function (event, editor) {
            var action = TransformActions.toggleBlock("heading2");
            action(editor);
            return true;
        }),
        onButtonLinkToggle: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarLinkToggle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: EditorTypes_1.InlineType.Link })
            .doAction(function (event, editor) {
            var action = TransformActions.toggleLink;
            action(editor);
            return true;
        }),
        onButtonSetParagraph: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarSetParagraph")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "paragraph" })
            .doAction(function (event, editor) {
            var action = TransformActions.setTextBlockType("paragraph");
            action(editor);
            return true;
        }),
        onButtonSetBlockQuote: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarSetBlockQuote")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "blockQuote" })
            .doAction(function (event, editor) {
            var action = TransformActions.setTextBlockType("blockQuote");
            action(editor);
            return true;
        }),
        onButtonSetHeading1: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarSetHeading1")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "heading1" })
            .doAction(function (event, editor) {
            var action = TransformActions.setTextBlockType("heading1");
            action(editor);
            return true;
        }),
        onButtonSetHeading2: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarSetHeading2")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "heading2" })
            .doAction(function (event, editor) {
            var action = TransformActions.setTextBlockType("heading2");
            action(editor);
            return true;
        }),
        onButtonSetBulletList: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarSetBulletList")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "listItem", listType: "bullet" })
            .doAction(function (event, editor) {
            var action = TransformActions.setListItem("bullet");
            action(editor);
            return true;
        }),
        onButtonSetNumberedList: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarSetNumberedList")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "listItem", listType: "number" })
            .doAction(function (event, editor) {
            var action = TransformActions.setListItem("number");
            action(editor);
            return true;
        }),
        onButtonClearListItems: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("toolbarClearListItems")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "toolbar", button: "clearListItems" })
            .doAction(function (event, editor) {
            var action = TransformActions.clearListItems();
            action(editor);
            return true;
        }),
    };
});
