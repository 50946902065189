"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLIPBOARD_FORMAT_QWILR_SRC_BLOCK_ID = exports.copyCutHandler = void 0;
var copyCutHandler = function (onEvent, blockId) {
    return function (event) {
        var _a, _b, _c;
        var result = onEvent(event);
        if ((_b = (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.items.length) !== null && _b !== void 0 ? _b : 0 > 0) {
            (_c = event.clipboardData) === null || _c === void 0 ? void 0 : _c.setData(exports.CLIPBOARD_FORMAT_QWILR_SRC_BLOCK_ID, blockId);
        }
        return result;
    };
};
exports.copyCutHandler = copyCutHandler;
exports.CLIPBOARD_FORMAT_QWILR_SRC_BLOCK_ID = "application/x-qwilr-source-block-id";
