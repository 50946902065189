"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleMark = exports.replaceMark = exports.removeMark = exports.addMark = exports.getCurrentMarks = void 0;
var slate_1 = require("slate");
var Text_1 = require("ContentTokens/Models/Text");
var lodash_1 = require("lodash");
var getCurrentMarks = function (editor, options) {
    var _a, _b;
    if (options === void 0) { options = { match: "all" }; }
    var selection = editor.selection;
    if (!selection) {
        return [];
    }
    if (slate_1.Range.isExpanded(selection)) {
        var nodes = slate_1.Editor.nodes(editor, {
            match: Text_1.Text.isText,
        });
        if (!nodes) {
            return [];
        }
        var nodeMarks = Array.from(nodes).map(function (nodeEntry) {
            var _a = __read(nodeEntry, 1), node = _a[0];
            return node.marks;
        });
        return options.match === "all" ? lodash_1.intersectionBy.apply(void 0, __spreadArray(__spreadArray([], __read(nodeMarks), false), [getMarkKey], false)) : (0, lodash_1.flatten)(nodeMarks);
    }
    var marks = (_b = (_a = slate_1.Editor.marks(editor)) === null || _a === void 0 ? void 0 : _a.marks) !== null && _b !== void 0 ? _b : [];
    return marks;
};
exports.getCurrentMarks = getCurrentMarks;
var addMark = function (mark, editor, options) {
    if (options === void 0) { options = { match: function () { return true; } }; }
    var selection = editor.selection;
    if (selection) {
        if (slate_1.Range.isExpanded(selection)) {
            slate_1.Editor.withoutNormalizing(editor, function () {
                slate_1.Transforms.setNodes(editor, null, { match: Text_1.Text.isText, split: true });
                Array.from(slate_1.Editor.nodes(editor, {
                    match: Text_1.Text.isText,
                })).forEach(function (entry) {
                    var _a = __read(entry, 2), text = _a[0], path = _a[1];
                    var marks = text.marks;
                    if (!marks.some(function (m) { return isSameMark(mark, m); }) && options.match(entry)) {
                        var newMarks = marks.concat(mark);
                        slate_1.Transforms.setNodes(editor, { marks: newMarks }, {
                            at: path,
                        });
                    }
                });
            });
        }
        else {
            var currentMarks = getCurrentMarks(editor);
            var newMarks = __spreadArray(__spreadArray([], __read(currentMarks), false), [mark], false);
            editor.marks = { marks: newMarks };
        }
    }
};
exports.addMark = addMark;
var removeMark = function (mark, editor, options) {
    if (options === void 0) { options = {
        scope: "selection",
        match: function () { return true; },
    }; }
    var selection = editor.selection;
    if (options.scope === "selection" ? selection : true) {
        if (options.scope === "selection" ? selection && slate_1.Range.isExpanded(selection) : true) {
            var at_1 = options.scope === "selection" ? undefined : [];
            slate_1.Editor.withoutNormalizing(editor, function () {
                if (options.scope === "selection") {
                    slate_1.Transforms.setNodes(editor, null, { match: Text_1.Text.isText, split: true, at: at_1 });
                }
                Array.from(slate_1.Editor.nodes(editor, {
                    match: Text_1.Text.isText,
                    at: at_1,
                })).forEach(function (entry) {
                    var _a = __read(entry, 2), text = _a[0], path = _a[1];
                    var marks = text.marks;
                    if (marks.some(function (m) { return isSameMark(mark, m); }) && options.match(entry)) {
                        var newMarks = marks.filter(function (m) { return !isSameMark(mark, m); });
                        slate_1.Transforms.setNodes(editor, { marks: newMarks }, {
                            at: path,
                        });
                    }
                });
            });
        }
        else {
            var marks = getCurrentMarks(editor);
            var newMarks = marks.filter(function (f) { return !isSameMark(mark, f); });
            editor.marks = { marks: newMarks };
        }
    }
};
exports.removeMark = removeMark;
var toggleMark = function (mark, editor) {
    var marks = getCurrentMarks(editor);
    var isMarkActive = marks.some(function (m) { return m.type == mark.type; });
    if (isMarkActive) {
        removeMark(mark, editor);
    }
    else {
        addMark(mark, editor);
    }
};
exports.toggleMark = toggleMark;
var replaceMark = function (oldType, newMark, editor) {
    removeMark(oldType, editor);
    addMark(newMark, editor);
};
exports.replaceMark = replaceMark;
var isMarkType = function (val) {
    return (0, lodash_1.isString)(val) && Object.values(Text_1.MarkType).includes(val);
};
var isSameMark = function (markA, markB) {
    if (isMarkType(markA) || isMarkType(markB)) {
        var typeA = isMarkType(markA) ? markA : markA.type;
        var typeB = isMarkType(markB) ? markB : markB.type;
        return typeA === typeB;
    }
    if (markA.type === Text_1.MarkType.Comment && markB.type === Text_1.MarkType.Comment) {
        return markA.data.id === markB.data.id;
    }
    return markA.type === markB.type;
};
var getMarkKey = function (mark) {
    if (mark.type === Text_1.MarkType.Comment) {
        return "comment:".concat(mark.data.id);
    }
    else if (mark.type === Text_1.MarkType.Color) {
        return "color:".concat(mark.data.color.abs, ":").concat(mark.data.color.themeIndex);
    }
    else {
        return mark.type;
    }
};
