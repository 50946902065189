"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./QwilrPay.css"));
var mobx_react_1 = require("mobx-react");
var QwilrPayButton = (function () {
    function QwilrPayButton(createUi, account, block, integrationService, payNowDisabler, telemetry, mountReactComponent) {
        this.ui = createUi(require("Project/Modules/Accept/Controls/QwilrPay/QwilrPayButton.html"), {
            $init: function (scope) {
                scope.ui.qwilrPayButton = mountReactComponent(react_1.default.createElement(QwilrPayButtonComponent, { payNowSettings: block.settings.payNow, updateOptionalPayment: updateOptionalPayment, updatePartPayment: updatePartPayment }));
            },
        });
        var updateOptionalPayment = function (isOptional) {
            block.settings.payNow.enableOptionalPayment = isOptional;
        };
        var updatePartPayment = function (isPartPaymentEnabled, partPaymentType, partPaymentAmount) {
            block.settings.payNow.enablePartPayment = isPartPaymentEnabled;
            block.settings.payNow.partPaymentType = partPaymentType;
            block.settings.payNow.partPaymentAmount = partPaymentAmount;
        };
    }
    return QwilrPayButton;
}());
exports.default = QwilrPayButton;
var QwilrPayButtonComponent = (0, mobx_react_1.observer)(function (_a) {
    var payNowSettings = _a.payNowSettings, updateOptionalPayment = _a.updateOptionalPayment, updatePartPayment = _a.updatePartPayment;
    (0, react_1.useEffect)(function () { }, []);
    var _b = __read((0, react_1.useState)(payNowSettings.enableOptionalPayment), 2), isOptionalPaymentEnabled = _b[0], setOptionalPayment = _b[1];
    var _c = __read((0, react_1.useState)({
        enabled: payNowSettings.enablePartPayment,
        type: payNowSettings.partPaymentType,
        amount: payNowSettings.partPaymentAmount,
    }), 2), partPayment = _c[0], setPartPayment = _c[1];
    var optionalPaymentCallback = function (isOptional) {
        setOptionalPayment(isOptional);
        updateOptionalPayment(isOptional);
    };
    var partPaymentCallback = function (enabled, type, amount) {
        setPartPayment({ enabled: enabled, type: type, amount: amount });
        updatePartPayment(enabled, type, amount);
    };
    return (react_1.default.createElement(kaleidoscope_1.Popover, { size: kaleidoscope_1.PopoverSize.Large, position: kaleidoscope_1.PopoverPosition.Bottom, onClickStopPropagation: true, button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.IconButton, __assign({ size: kaleidoscope_1.ButtonSize.Medium, isRound: true, className: styles.popoverIconButton, icon: react_1.default.createElement(icons_1.QwilrPay, { className: styles.qwilrPayIcon }), tooltip: { content: "Qwilr Pay" }, buttonState: kaleidoscope_1.ButtonState.Idle }, buttonProps))); }, caret: true, autoStack: true },
        react_1.default.createElement(QwilrPayMenu, { qPayConnected: true, optionalPayment: isOptionalPaymentEnabled, partPayment: partPayment, updateOptionalPayment: optionalPaymentCallback, updatePartPayment: partPaymentCallback })));
});
var QwilrPayMenu = (0, mobx_react_1.observer)(function (_a) {
    var qPayConnected = _a.qPayConnected, optionalPayment = _a.optionalPayment, partPayment = _a.partPayment, updateOptionalPayment = _a.updateOptionalPayment, updatePartPayment = _a.updatePartPayment;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" }, qPayConnected ? (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", align: "center" },
            react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "l" }, "Payments"),
            react_1.default.createElement(kaleidoscope_1.OptionMenu, { button: react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.OptionsHorizontal, null) }), autoStack: true },
                react_1.default.createElement(kaleidoscope_1.OptionMenuItem, null, "Connect Payment Gateway"),
                react_1.default.createElement(kaleidoscope_1.OptionMenuItem, null, "Payments Library"),
                react_1.default.createElement(kaleidoscope_1.OptionMenuItem, null,
                    react_1.default.createElement(kaleidoscope_1.Text, { strong: true }, "Need help?"),
                    " Talk to our team"))),
        react_1.default.createElement(kaleidoscope_1.Separator, null),
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", paddingBottom: "m" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", align: "center" },
                react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "m" }, "Payment Options"),
                react_1.default.createElement(kaleidoscope_1.OptionMenu, { button: react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.ChevronDown, null) }), autoStack: true },
                    react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { onClick: function () { } }, "Sample Option"))),
            react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
                react_1.default.createElement(kaleidoscope_1.Tooltip, __assign({ className: styles.previewTooltips, position: kaleidoscope_1.TooltipPosition.Right, content: react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xs", padding: "xxs" },
                        react_1.default.createElement("img", { src: "Assets/Images/qwilrPay/optional-payment-preview.svg" }),
                        react_1.default.createElement(kaleidoscope_1.Text, null, "Buyer will see this")), autoStack: true, wrapTarget: true, offset: 30 }, (!optionalPayment ? { show: false } : {})),
                    react_1.default.createElement(kaleidoscope_1.Toggle, { className: styles.paymentOptionToggle, value: optionalPayment, onChange: function (newSelected) { return updateOptionalPayment(newSelected); }, label: "Enable optional payment" })),
                react_1.default.createElement(kaleidoscope_1.Toggle, { className: styles.paymentOptionToggle, value: partPayment.enabled, onChange: function (newSelected) { return updatePartPayment(newSelected, partPayment.type, partPayment.amount); }, label: "Enable part payment" }),
                partPayment.enabled && (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xxs" },
                    react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "xs" }, "Choose percentage or fixed amount"),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", className: styles.partPaymentInputGroup, justify: "spaceBetween", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.TextInput, { label: "", labelHidden: true, type: kaleidoscope_1.TextInputType.Number, className: styles.paymentTypeInput, size: kaleidoscope_1.TextInputSize.Small, variant: kaleidoscope_1.TextInputVariant.Borderless, value: "".concat(partPayment.amount), onChange: function (value) { return updatePartPayment(true, partPayment.type, Number(value)); } }),
                        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.partPaymentTypeToggle, direction: "horizontal" },
                            react_1.default.createElement(kaleidoscope_1.Button, { className: styles.toggleButton, size: kaleidoscope_1.ButtonSize.Small, onClick: function () { return updatePartPayment(true, "percentage", partPayment.amount); }, type: partPayment.type === "percentage" ? kaleidoscope_1.ButtonType.Secondary : kaleidoscope_1.ButtonType.Tertiary }, "%"),
                            react_1.default.createElement(kaleidoscope_1.Button, { className: styles.toggleButton, size: kaleidoscope_1.ButtonSize.Small, onClick: function () { return updatePartPayment(true, "fixed", partPayment.amount); }, type: partPayment.type !== "percentage" ? kaleidoscope_1.ButtonType.Secondary : kaleidoscope_1.ButtonType.Tertiary }, "$"))))))),
        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.previewWrapper, padding: "s", justify: "spaceBetween", direction: "horizontal" },
            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.previewTextWrapper, direction: "horizontal", gap: "xs", align: "center", justify: "start" },
                react_1.default.createElement(icons_1.Preview, { className: styles.previewIcon }),
                react_1.default.createElement(kaleidoscope_1.Label, { size: "s" }, "Preview Payment Mode")),
            react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small }, "Preview")))) : (react_1.default.createElement(QwilrPayIntegrationPrompt, null))));
});
var QwilrPayIntegrationPrompt = function () {
    return (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "m", className: styles.integrationPrompt, align: "center" },
        react_1.default.createElement(kaleidoscope_1.Heading, { strong: true, level: "3", className: styles.activateHeading }, "Activate Payments"),
        react_1.default.createElement(kaleidoscope_1.Text, { align: "center" },
            "Connect QwilrPay to get started",
            react_1.default.createElement("br", null),
            "and get paid instantly"),
        react_1.default.createElement("img", { className: styles.qPayVideoPlaceholder, src: "Assets/Images/qwilrPay/qwilr-pay-intro-video-placeholder.png" }),
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s", justify: "spaceAround" },
            react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, onClick: function () { } }, "Learn More"),
            react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Primary, onClick: function () { } }, "Connect")),
        react_1.default.createElement(kaleidoscope_1.Link, { href: "#" }, "Connect an existing Stripe Account")));
};
