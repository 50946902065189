"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Password = exports.PhoneNumber = exports.OptionGroupSalesTeamSize = exports.OptionGroupCompanySize = exports.CompanySize = exports.Industry = exports.CompanyName = exports.Email = exports.LoginPassword = exports.LastName = exports.FirstName = exports.Name = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var lodash_1 = require("lodash");
var kaleidoscope_2 = require("@qwilr/kaleidoscope");
var PasswordPolicies_1 = require("@CommonFrontendBackend/User/Password/PasswordPolicies");
var ValidateStrings_1 = require("@CommonFrontendBackend/Utils/ValidateStrings");
var FirstName = {
    id: "firstName",
    type: kaleidoscope_1.FormType.Text,
    props: {
        label: "First Name",
        placeholder: "Jackie",
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please provide your first name";
        }
    },
};
exports.FirstName = FirstName;
var LastName = {
    id: "lastName",
    type: kaleidoscope_1.FormType.Text,
    props: {
        label: "Last Name",
        placeholder: "Riley",
    },
    validate: lodash_1.noop,
};
exports.LastName = LastName;
var Name = {
    id: "name",
    type: kaleidoscope_1.FormType.Text,
    props: {
        label: "Name",
        placeholder: "Jackie Riley",
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please provide your name";
        }
        var validateName = (0, ValidateStrings_1.checkBasicText)(value);
        if (validateName.valid === false) {
            return "A name ".concat(validateName.error);
        }
    },
};
exports.Name = Name;
var Email = {
    id: "email",
    type: kaleidoscope_1.FormType.Text,
    props: {
        id: "login-email",
        type: kaleidoscope_1.TextInputType.Email,
        label: "Work email",
        placeholder: "jackie@riley.com",
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please provide your email";
        }
    },
};
exports.Email = Email;
var LoginPassword = {
    id: "password",
    type: kaleidoscope_1.FormType.Text,
    props: {
        id: "login-password",
        type: kaleidoscope_1.TextInputType.Password,
        label: "Password",
        placeholder: "••••••••",
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please provide a password";
        }
        else if (value.length < 8) {
            return "Please choose a password of at least 8 characters";
        }
        else if (value.length > 100) {
            return "Please choose a password of at most 100 characters";
        }
        else if (["12345678", "password"].includes(value)) {
            return "Please choose a different password";
        }
    },
};
exports.LoginPassword = LoginPassword;
var Password = {
    id: "password",
    type: kaleidoscope_1.FormType.Text,
    props: {
        type: kaleidoscope_1.TextInputType.Password,
        label: "Password",
        placeholder: "••••••••••",
    },
    validate: function (value) {
        var _a;
        var result = (0, PasswordPolicies_1.validatePassword)(value);
        if (result.isValid) {
            return;
        }
        var errorMessages = (_a = {},
            _a[PasswordPolicies_1.ValidationError.Empty] = "Please provide a password",
            _a[PasswordPolicies_1.ValidationError.TooShort] = "Please choose a password of at least ".concat(PasswordPolicies_1.PASSWORD_MIN_LENGTH, " characters"),
            _a[PasswordPolicies_1.ValidationError.TooLong] = "Please choose a password of at most ".concat(PasswordPolicies_1.PASSWORD_MAX_LENGTH, " characters"),
            _a[PasswordPolicies_1.ValidationError.InsufficientComplexity] = "Password requires a combination of uppercase and lowercase letters, numbers and/or symbols",
            _a);
        return errorMessages[result.error];
    },
};
exports.Password = Password;
var CompanyName = {
    id: "companyName",
    type: kaleidoscope_1.FormType.Text,
    props: {
        label: "What's your company name?",
        placeholder: "Acme Corp",
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please provide your company name";
        }
        var validateName = (0, ValidateStrings_1.checkBasicText)(value);
        if (validateName.valid === false) {
            return "A company name ".concat(validateName.error);
        }
    },
};
exports.CompanyName = CompanyName;
var Industry = {
    id: "industry",
    type: kaleidoscope_1.FormType.Dropdown,
    props: {
        label: "Find your industry",
        placeholder: "What industry do you work in?",
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please select your industry";
        }
    },
    options: [
        {
            id: "agency",
            name: "Agency",
        },
        {
            id: "consultancy",
            name: "Consultancy",
        },
        {
            id: "events",
            name: "Events",
        },
        {
            id: "nonprofit",
            name: "Nonprofit",
        },
        {
            id: "software",
            name: "Software",
        },
        {
            id: "personal",
            name: "Personal",
        },
        {
            id: "other",
            name: "Other",
            isOtherOption: true,
        },
    ],
};
exports.Industry = Industry;
var CompanySize = {
    id: "companySize",
    type: kaleidoscope_1.FormType.Dropdown,
    props: {
        label: "Company Size",
        placeholder: "Select company size",
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please select a company size";
        }
    },
    options: [
        {
            id: "1",
            name: "1 - 10",
        },
        {
            id: "11",
            name: "11 - 50",
        },
        {
            id: "51",
            name: "51 - 200",
        },
        {
            id: "201",
            name: "201 - 500",
        },
        {
            id: "500",
            name: "500+",
        },
    ],
};
exports.CompanySize = CompanySize;
var OptionGroupCompanySize = {
    id: "optionGroupCompanySize",
    type: kaleidoscope_1.FormType.OptionGroup,
    props: {
        size: kaleidoscope_2.OptionGroupSize.Large,
        label: "Company size",
        options: [
            {
                label: "1-20",
                value: "1",
            },
            {
                label: "21-50",
                value: "21",
            },
            {
                label: "51-200",
                value: "51",
            },
            {
                label: "201+",
                value: "201",
            },
        ],
        onClickHandler: lodash_1.noop,
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please select a company size";
        }
    },
};
exports.OptionGroupCompanySize = OptionGroupCompanySize;
var OptionGroupSalesTeamSize = {
    id: "optionGroupSalesTeamSize",
    type: kaleidoscope_1.FormType.OptionGroup,
    props: {
        size: kaleidoscope_2.OptionGroupSize.Large,
        label: "How big is your sales team?",
        options: [
            {
                label: "0",
                value: "0",
            },
            {
                label: "1-3",
                value: "1",
            },
            {
                label: "4-20",
                value: "4",
            },
            {
                label: "21+",
                value: "21",
            },
        ],
        onClickHandler: lodash_1.noop,
    },
    validate: function (value) {
        if (value.length === 0) {
            return "Please select a sales team size";
        }
    },
};
exports.OptionGroupSalesTeamSize = OptionGroupSalesTeamSize;
var PhoneNumber = {
    id: "phoneNumber",
    type: kaleidoscope_1.FormType.Text,
    props: {
        type: kaleidoscope_1.TextInputType.Telephone,
        label: "Phone Number",
        placeholder: "(+61) 123 456 789",
    },
    validate: lodash_1.noop,
};
exports.PhoneNumber = PhoneNumber;
