"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedTextColor = void 0;
var slate_1 = require("slate");
var lodash_1 = require("lodash");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var Text_1 = require("@CommonFrontend/ContentTokens/Models/Text");
var Styleable_1 = require("Project/QwilrEditor/WidgetRegistry/Utils/Styleable");
var TEXT_COLOR_PATH = "color";
var getSelectedTextColor = function (editor, selectedTextWidgets) {
    var e_1, _a;
    var _b;
    var color = undefined;
    var containsOverrides = false;
    var inheritedColor = undefined;
    var _loop_1 = function (node) {
        if (Text_1.Text.isText(node)) {
            var colorMark = node.marks.find(Text_1.isColorMark);
            var currentColor = (_b = (colorMark ? colorMark.data.color : undefined)) !== null && _b !== void 0 ? _b : inheritedColor;
            if (colorMark) {
                containsOverrides = true;
            }
            if (!currentColor) {
                return "continue";
            }
            else if (!color) {
                color = (0, commonTypes_1.cloneIColorObjectType)(currentColor);
                return "continue";
            }
            else if (!(0, Styleable_1.areColorsEqual)(currentColor, color)) {
                color = undefined;
                return "break";
            }
        }
        if (!slate_1.Element.isElement(node)) {
            return "continue";
        }
        var textWidget = selectedTextWidgets.find(function (textInfo) { return textInfo.element === node; });
        if (textWidget) {
            var textColor = (0, lodash_1.get)(textWidget.style, TEXT_COLOR_PATH);
            inheritedColor = textColor ? (0, commonTypes_1.cloneIColorObjectType)(textColor) : undefined;
        }
    };
    try {
        for (var _c = __values(slate_1.Editor.nodes(editor)), _d = _c.next(); !_d.done; _d = _c.next()) {
            var _e = __read(_d.value, 1), node = _e[0];
            var state_1 = _loop_1(node);
            if (state_1 === "break")
                break;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return {
        color: color ? (0, GetBlockStyle_1.convertColorObjToColor)(color) : undefined,
        containsOverrides: containsOverrides,
    };
};
exports.getSelectedTextColor = getSelectedTextColor;
