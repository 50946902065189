"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var FeatureFlag_1 = require("@CommonFrontendBackend/FeatureFlag");
var GetSourceParam_1 = require("Project/Utils/GetSourceParam");
exports.default = (function ($http, loadingIndicator, showAcknowledgeModal, createUi, $state, $location, $log, apiBaseUrl, $q, styleWorker, usingFeature, invoke, savedStyleService, projectMigrationService) {
    return function (projectId) { return __awaiter(void 0, void 0, void 0, function () {
        var projectGetResponse, project, projectStyleFetchPromises, err_1, errorMessage, cantFindProject;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!projectId) {
                        $state.go("dashboard", { nav: "pages" });
                        return [2, $q.reject(new Error("No project provided"))];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 8]);
                    loadingIndicator.show("Page");
                    return [4, $http.get("".concat(apiBaseUrl, "/projects/").concat(projectId).concat((0, GetSourceParam_1.getSourceParam)($location)))];
                case 2:
                    projectGetResponse = _a.sent();
                    if ((projectGetResponse === null || projectGetResponse === void 0 ? void 0 : projectGetResponse.status) != 200) {
                        throw new Error("Unknown project retrieval error, status ".concat(projectGetResponse.status));
                    }
                    project = projectGetResponse.data;
                    if (!project) return [3, 4];
                    return [4, projectMigrationService.runProjectMigrations(project)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    if (!(project && (0, FeatureFlag_1.isUsingTCDC)(usingFeature))) return [3, 6];
                    projectStyleFetchPromises = [savedStyleService.getAllSavedStyles()];
                    if (!project.usingTCDC) {
                        projectStyleFetchPromises.push(styleWorker.loadProjectStylesheet(projectId));
                    }
                    return [4, Promise.all(projectStyleFetchPromises)];
                case 5:
                    _a.sent();
                    styleWorker.delete("qwilr-style-banks");
                    _a.label = 6;
                case 6: return [2, project];
                case 7:
                    err_1 = _a.sent();
                    loadingIndicator.hide();
                    errorMessage = !!err_1.data && !!err_1.data.error && err_1.data.error.message;
                    cantFindProject = errorMessage === "We couldn't find that project";
                    if (err_1.status === 400 && cantFindProject) {
                        showAcknowledgeModal({
                            title: "Project has been deleted",
                            explainer: "It looks like that project was recently deleted by another user of your account",
                        });
                    }
                    else if (sessionStorage.isRedirecting) {
                        delete sessionStorage.isRedirecting;
                    }
                    else {
                        showAcknowledgeModal({
                            title: "Can't open project",
                            explainer: createUi(require("./CantOpenProjectModalText.html")),
                        });
                    }
                    $state.go("dashboard", { nav: "pages" });
                    $log.error(err_1);
                    return [3, 8];
                case 8: return [2];
            }
        });
    }); };
});
