"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToHeading2 = exports.convertToHeading1 = void 0;
var slate_1 = require("slate");
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var TransformActions_1 = require("./TransformActions");
exports.default = (function (di) {
    return {
        onHeading1ShortcutHash: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("heading1ShortcutHash")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Space)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .inBlockType(["paragraph", "heading2"])
            .hasTextSequence(/^\#$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, exports.convertToHeading1)(editor);
            return true;
        }),
        onHeading2ShortcutHash: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("heading2ShortcutHash")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Space)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .inBlockType(["paragraph", "heading1"])
            .hasTextSequence(/^\#\#$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, exports.convertToHeading2)(editor);
            return true;
        }),
    };
});
var convertToHeading1 = function (editor) {
    slate_1.Transforms.insertText(editor, " ");
    slate_1.Transforms.delete(editor, { distance: 2, reverse: true });
    (0, TransformActions_1.setValidBlocks)(editor, "heading1", { clearStyleOverrides: true });
};
exports.convertToHeading1 = convertToHeading1;
var convertToHeading2 = function (editor) {
    slate_1.Transforms.insertText(editor, " ");
    slate_1.Transforms.delete(editor, { distance: 3, reverse: true });
    (0, TransformActions_1.setValidBlocks)(editor, "heading2", { clearStyleOverrides: true });
};
exports.convertToHeading2 = convertToHeading2;
