"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStyleForPropagation = void 0;
var lodash_1 = require("lodash");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var Styleable_1 = require("Project/QwilrEditor/WidgetRegistry/Utils/Styleable");
var groupOverridesByElementType = function (textWidgets) {
    var e_1, _a;
    var result = new Map();
    try {
        for (var textWidgets_1 = __values(textWidgets), textWidgets_1_1 = textWidgets_1.next(); !textWidgets_1_1.done; textWidgets_1_1 = textWidgets_1.next()) {
            var _b = textWidgets_1_1.value, element = _b.element, styleableConfig = _b.styleableConfig, overrides = _b.overrides;
            var existingValue = result.get(element.type);
            result.set(element.type, existingValue === undefined
                ? {
                    styleableConfig: styleableConfig,
                    overrides: [overrides],
                }
                : {
                    styleableConfig: existingValue.styleableConfig,
                    overrides: __spreadArray(__spreadArray([], __read(existingValue.overrides), false), [overrides], false),
                });
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (textWidgets_1_1 && !textWidgets_1_1.done && (_a = textWidgets_1.return)) _a.call(textWidgets_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return __spreadArray([], __read(result.entries()), false).map(function (_a) {
        var _b = __read(_a, 2), elementType = _b[0], _c = _b[1], styleableConfig = _c.styleableConfig, overrides = _c.overrides;
        return ({
            elementType: elementType,
            styleableConfig: styleableConfig,
            overrides: overrides,
        });
    });
};
var onlyCommonOverrides = function (baseStyle, overridesArray, styleableConfig) {
    var style = (0, lodash_1.cloneDeep)(baseStyle);
    overridesArray.forEach(function (overrides, index) {
        styleableConfig.properties.forEach(function (property) {
            var currentValue = (0, lodash_1.get)(overrides, property.path);
            var existingValue = (0, lodash_1.get)(style, property.path);
            if (index === 0 && currentValue !== undefined) {
                (0, lodash_1.set)(style, property.path, currentValue);
            }
            else if (index > 0 && existingValue !== undefined) {
                if (currentValue === undefined || !(0, Styleable_1.styleablePropertiesAreEqual)(existingValue, currentValue, property)) {
                    (0, lodash_1.unset)(style, property.path);
                }
            }
        });
    });
    return style;
};
var getStyleForPropagation = function (textWidgets, textColor) {
    var overridesByWidgetType = groupOverridesByElementType(textWidgets);
    if (!overridesByWidgetType[0] || overridesByWidgetType.length !== 1) {
        return undefined;
    }
    var _a = overridesByWidgetType[0], elementType = _a.elementType, styleableConfig = _a.styleableConfig, overrides = _a.overrides;
    var baseStyle = {};
    var hasTextColorOverride = false;
    var textColorProperty = styleableConfig.properties.find(function (p) { return p.type === "color" && p.path === "color"; });
    if (textColorProperty && textColor.containsOverrides && textColor.color !== undefined) {
        (0, lodash_1.set)(baseStyle, textColorProperty.path, (0, GetBlockStyle_1.convertColorToColorObj)(textColor.color));
        hasTextColorOverride = true;
    }
    var commonOverrides = onlyCommonOverrides(baseStyle, overrides, styleableConfig);
    var fontSizePropagatesToParagraph = undefined;
    var fontSizePropertyShouldGoToParagraph = !!styleableConfig.properties.find(function (p) { return p.type === "fontSize" && p.useParagraphBlockFontSize; }) &&
        Object.keys(commonOverrides).includes("size");
    if (fontSizePropertyShouldGoToParagraph) {
        var size = commonOverrides["size"];
        delete commonOverrides["size"];
        fontSizePropagatesToParagraph = {
            size: size,
        };
    }
    if ((0, lodash_1.isEmpty)(commonOverrides) && !fontSizePropagatesToParagraph) {
        return undefined;
    }
    return {
        elementType: elementType,
        styleableConfig: styleableConfig,
        overrides: commonOverrides,
        hasTextColorOverride: hasTextColorOverride,
        fontSizePropagatesToParagraph: fontSizePropagatesToParagraph,
    };
};
exports.getStyleForPropagation = getStyleForPropagation;
