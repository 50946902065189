import "Application/SettingsAngular/Notifications/V2Notifications.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/SettingsAngular/Notifications/V2Notifications.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VU0W6jMBB8z1esdDopkeLIdnAC9Ol6p/uA+4HIgEOcGhvZpkd16r+fMKRA2vSllfqId3a8M4N3cyC0lRlXGP4tAP7Kwp9SIBh/v1s8LzaXKgnVirdoQERxXLd3CwCuZKmR9KJyKThvhc9Ps1Y6Jd7u+q6TkOXJj981LwqpyxSG78zYQlhkeSEbl8Ijt0uE+sM/4QzlRntr1OFAMrU/nwkpV11jbpSxKXzD+P7Xj9+BiucPpTWNLtBQtGXGl3gNJE7WQPZsDXhDcWivuC2lRpnx3lSXe13Nc6lL1LrxtofVTOQ2iPSi9SgYkoISRz9TdsU1mdzMuaKRy1uu3dHYKgVttJjBWIAdlWhRxp10KbAuNIBK6ktKZI87Pyddu9B1a6jbAvfTFCN6lWLywdR6TAqkbsEZJYsuo2UcA4kjoGy7uhnkkm4J0IgBZSygCulqxZ/S4MyrHzQX2gvbHZ8b5+XxKUwktB9LE9HxF4gmSQwk2QGNkvdUR51qDJRFn646+QLVlDGgOIH4XdEdiGGg2/jTRfNhwYXnb3ut7OrtZD1GanRxg9ErSB4ggzmXLTJK7W0KkgazRnvw/MEVPdMrI6YUMjf6J2+8NPqFp1m9Ec+wGd6gcI098ly8tJfzKQSUUHN/6neNVKpPYg+EYKC4y+H5PzH5/yxDBgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = '_12xibal0';
export var content = '_12xibal1';
export var framedNotificationIcon = '_12xibal2';
export var v2NotificationCard = '_12xibal3';
export var toggleStyle = '_12xibal4';
export var dropDownStyle = '_12xibal5';
export var customNumberSelect = '_12xibal6';
export var engagementIconStyle = '_12xibal7';
export var viewershipIconStyle = '_12xibal8';
export var transactionIconStyle = '_12xibal9';
export var inputUnitText = '_12xibala';
export var settingsRow = _7a468({defaultClassName:'_12xibalb',variantClassNames:{hasDivider:{true:'_12xibalc'}},defaultVariants:{},compoundVariants:[]});
export var alertIcon = '_12xibald';
export var blueSvg = '_12xibale';