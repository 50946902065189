"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addNormalization = void 0;
var Text_1 = require("ContentTokens/Models/Text");
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
var NormalizeBlock_1 = require("./NormalizeBlock");
var NormalizeInline_1 = require("./NormalizeInline");
var NormalizeText_1 = require("./NormalizeText");
var DefaultSchema_1 = require("./DefaultSchema");
var NormalizeSelectedListItems_1 = require("./NormalizeSelectedListItems");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
var Paragraph_1 = require("../WidgetRegistry/Widgets/Paragraph");
var addNormalization = function (editor, schema, invisibleOverridesNormalizer) {
    var currentSchema = schema || DefaultSchema_1.schema;
    var baseNormalize = editor.normalizeNode;
    editor.normalizeNode = function (entry) {
        var _a = __read(entry, 2), node = _a[0], path = _a[1];
        var didNormalize = false;
        if (path.length === 0) {
            didNormalize = normalizeEditor(node, currentSchema);
        }
        else if ((0, Utils_1.isBlockElement)(node)) {
            didNormalize = (0, NormalizeBlock_1.normalizeBlock)(entry, editor, currentSchema, invisibleOverridesNormalizer);
        }
        else if ((0, Utils_1.isInline)(node)) {
            didNormalize = (0, NormalizeInline_1.normalizeInline)(entry, editor, currentSchema);
        }
        else if (Text_1.Text.isText(node)) {
            didNormalize = (0, NormalizeText_1.normalizeText)(entry, editor, currentSchema, invisibleOverridesNormalizer);
        }
        if (didNormalize) {
            return;
        }
        baseNormalize(entry);
    };
};
exports.addNormalization = addNormalization;
var normalizeEditor = function (editor, editorSchema) {
    var _a, _b;
    var children = editor.children;
    if (children.length == 0) {
        slate_1.Transforms.insertNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: [0] });
        return true;
    }
    for (var i = 0; i < children.length; i++) {
        var element = children[i];
        if (!element) {
            slate_1.Transforms.removeNodes(editor, { at: [i] });
            return true;
        }
        if (!((_a = (0, EditorWidgetRegistry_1.getGenericConfigByType)(element.type)) === null || _a === void 0 ? void 0 : _a.validEditors.includes(editorSchema.name))) {
            if ((0, Utils_1.isBlockElement)(element)) {
                slate_1.Transforms.setNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: [i] });
            }
            else {
                slate_1.Transforms.wrapNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: [i] });
            }
            return true;
        }
    }
    if ((0, EditorWidgetRegistry_1.getGenericConfigByType)(children[0].type)
        ?
            !((_b = (0, EditorWidgetRegistry_1.getGenericConfigByType)(children[0].type)) === null || _b === void 0 ? void 0 : _b.firstLastConfiguration.isValidFirstChild)
        :
            editorSchema.editor.validFirstChild && !editorSchema.editor.validFirstChild.includes(children[0].type)) {
        slate_1.Transforms.insertNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: [0] });
        return true;
    }
    var didNormalizeListItems = (0, NormalizeSelectedListItems_1.normalizeSelectedListItems)(editor);
    if (didNormalizeListItems) {
        return true;
    }
    return false;
};
