import "Application/Project/Modules/Accept/Controls/QwilrPay/QwilrPay.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/Modules/Accept/Controls/QwilrPay/QwilrPay.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VUwW6jMBC99ytmD5WCBBF2MCHsT1TqB1QGO8SJsZFxElar/vvKuCVOQ9LugQPz3rx5zAyzPOUHPRQp/H0CqLRh3JSAugF6LQWD31M0qbXUpoQTNYvEvyQeeXtDlVzv9wilUcA3lIljX0LWDS5KpWhUIixv+xJqriw3LtxRxoRqSsCO9v609H6Q90PrQ2P0UbG56v3RbGnNX3mtFaPmz+RDPLChT9xspT6XsBOMcRXUxGPNs2B2VwJJr/ysYHmQiaQVlyNrq5VNzlw0O1sCStOAmoUyKE2fXdndhTq+336ZaarFJocNBrRGUaBHfEEhpSdhQuDjCWlZWfGtNvxO467l4ZdoO20sVTaQyH0lyYekMa5FKJhQIvnWfg6g72jtYv1l9jI0sx6Vptn+LKmYa9zPNABaahqhEqu7r9RhCMj7kFxpa3X7LX8yuBkNzmyi5YN9MaINlzAPM2nYjzmT7aVkG4Vdn/f4hT7VqR5MH2c4BpytYsAki66XsAQpFKcmadzvwpVdIFQw3sQuk46pLhOTLEYRpM9TPPuIr1wc4QkgKPZJuQNwdgFSn+GBVXEBnJSXQxEQcg2sRzkUwRpdA0WMs9QBRSBFYpxtPgG3SXMHIfcH4ebm/f/Jm9pf31sQUWs15XRhCnu0U7enjUR3b+n7P4pl6STLBQAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var partPaymentInputGroup = 'v6kox80';
export var partPaymentTypeToggle = 'v6kox81';
export var toggleButton = 'v6kox82';
export var paymentOptionToggle = 'v6kox83';
export var popoverIconButton = 'v6kox84';
export var qwilrPayIcon = 'v6kox85';
export var paymentTypeInput = 'v6kox86';
export var previewTooltips = 'v6kox87';
export var qPayVideoPlaceholder = 'v6kox88';
export var activateHeading = 'v6kox89';
export var integrationPrompt = 'v6kox8a';
export var previewWrapper = 'v6kox8b';
export var previewIcon = 'v6kox8c';
export var previewTextWrapper = 'v6kox8d';