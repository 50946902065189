import "Application/Project/QwilrEditor/Widgets/TextToolbar/Menus/MenuStyles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/Widgets/TextToolbar/Menus/MenuStyles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Uu27bMBTd/RVciliFGYh27ToyOqRT945FIVAkJdGheRWSsmUU/fdClGQ9EtgJ0ALdiPs459wX72NCJGTZJkS/ZgjtS+tkesYMtBPaRShVosLWUeN2M4QKyrnUGVYidRE6UjPH2BaU1TYbxyRRn/d7QlSwm/2e3XfQ5B9C/6BGUlwYYa3gX+6cKcXdT8+HMQMFJo6r5+2Sb0kH6q1ehQH1yJjQrodfB7WWhLKnzECp+TjJlialTDwyJ4/ikgRjScv/T9LKs58kd3mENmFRjbzroZcsvRuhAqx0EnSEUlkJXpucobozQj0ad2618NLQ2o4Pve5j0DoFtfUQU2odlO67gpPUfZgJFg1wCubwN+Bqpa26CIWjSje+0gtb1DwVdWLF5+FisnXVYO2eggUKx019uAHGqGJzTNDHq7AvcanHvVRAdmMWDVqM4pN3xjMff6AVzoXMcheh1XbdzByOwqQKTvgcIVo66GyWGVAKJyKnRwnmFdBP/QtJXZTOk7Q3PT3ncQemm9Vv+nQbBmnle7YhAcPFRfX4ljxzQY3Q7npBUV53wpd14xa/1YEXDTq4gZsCK+1bcK/fOB9e8aTfqu8I+I50k78eN0BPG4FtI0lRIQtK8tf+r68+qEcinrH7XsLww1DAplm8l5V3xKLW8Qel8dQApQYAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var textTypeMenuButton = '_11iogg60';
export var menuOptionButton = '_11iogg61';
export var iconOptionButton = '_11iogg62';
export var fontSizeContainer = '_11iogg63';
export var fontSizeInput = '_11iogg64';
export var fontSizeMenu = _7a468({defaultClassName:'_11iogg65',variantClassNames:{position:{top:'_11iogg66',left:'_11iogg67',right:'_11iogg68',bottom:'_11iogg69'},status:{entering:'_11iogg6a',entered:'_11iogg6b'}},defaultVariants:{},compoundVariants:[]});
export var fontSizeMenuScroll = '_11iogg6c';
export var textColorIcon = '_11iogg6d';
export var textColorBarBackground = 'var(--_11iogg6e)';
export var textColorBar = '_11iogg6f';